import { createBrowserRouter } from 'react-router-dom'

import { GeneralError } from '@/components/errors/general-error'
import { DefaultRoute, ProtectedRoute } from '@/components/routes'
import { PLATFORMS } from '@/config/platforms'

import { AppRoot } from './app/root'

export const createRouter = () =>
  createBrowserRouter([
    {
      path: '/auth/login',
      lazy: async () => {
        const { LoginRoute } = await import('./auth/login')
        return { Component: LoginRoute }
      },
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <AppRoot />
        </ProtectedRoute>
      ),
      errorElement: <GeneralError />,
      children: [
        {
          index: true,
          element: <DefaultRoute />,
        },
        // Admin routes
        {
          path: '/admin',
          children: [
            {
              index: true,
              element: <DefaultRoute redirectTo="/admin/users" />,
            },
            {
              path: 'users',
              lazy: async () => {
                const { UsersRoute } = await import('./app/users')
                return { Component: UsersRoute }
              },
            },
            {
              path: 'teams',
              children: [
                {
                  index: true,
                  lazy: async () => {
                    const { TeamsRoute } = await import('./app/teams/teams')
                    return { Component: TeamsRoute }
                  },
                },
                {
                  path: ':id',
                  lazy: async () => {
                    const { TeamUsersRoute } = await import(
                      './app/teams/team-users'
                    )
                    return { Component: TeamUsersRoute }
                  },
                },
              ],
            },
            {
              path: 'roles',
              children: [
                {
                  index: true,
                  lazy: async () => {
                    const { RolesRoute } = await import('./app/roles/list')
                    return { Component: RolesRoute }
                  },
                },
                {
                  path: ':id',
                  lazy: async () => {
                    const { RoleDetailsRoute } = await import(
                      './app/roles/details'
                    )
                    return { Component: RoleDetailsRoute }
                  },
                },
              ],
            },
            {
              path: 'permissions',
              lazy: async () => {
                const { PermissionsRoute } = await import('./app/permissions')
                return { Component: PermissionsRoute }
              },
            },
            {
              path: 'policies',
              children: [
                {
                  path: 'resources',
                  lazy: async () => {
                    const { ResourcePoliciesRoute } = await import(
                      './app/policies/resource'
                    )
                    return { Component: ResourcePoliciesRoute }
                  },
                },
                {
                  path: 'api',
                  lazy: async () => {
                    const { ApiPoliciesRoute } = await import(
                      './app/policies/api'
                    )
                    return { Component: ApiPoliciesRoute }
                  },
                },
              ],
            },
          ],
        },
        // Sales routes
        {
          path: 'sales',
          children: [
            {
              index: true,
              element: <DefaultRoute redirectTo="/sales/orders" />,
            },
            {
              path: 'orders',
              children: createOrdersRoute(),
            },
            {
              path: 'listing',
              lazy: async () => {
                const { ListingsRoute } = await import('./app/listings')
                return { Component: ListingsRoute }
              },
            },
            {
              path: 'cost-centers',
              children: [
                {
                  path: 'mapping',
                  lazy: async () => {
                    const { MappingRoute } = await import(
                      './app/cost-center/mapping'
                    )
                    return { Component: MappingRoute }
                  },
                },
                {
                  path: 'config',
                  lazy: async () => {
                    const { ConfigCostCenterRoute } = await import(
                      './app/cost-center/config'
                    )
                    return { Component: ConfigCostCenterRoute }
                  },
                },
                {
                  path: 'group-standards',
                  lazy: async () => {
                    const { GroupStandardsRoute } = await import(
                      './app/cost-center/group-standards'
                    )
                    return { Component: GroupStandardsRoute }
                  },
                },
                {
                  path: 'product-types',
                  lazy: async () => {
                    const { ProductTypesRoute } = await import(
                      './app/cost-center/product-types'
                    )
                    return { Component: ProductTypesRoute }
                  },
                },
              ],
            },
            {
              path: 'cases',
              lazy: async () => {
                const { CasesRoute } = await import('./app/cases')
                return { Component: CasesRoute }
              },
            },
            {
              path: 'refund-reasons',
              lazy: async () => {
                const { RefundReasonsRoute } = await import(
                  './app/refund-reasons'
                )
                return { Component: RefundReasonsRoute }
              },
            },
            {
              path: 'tracking',
              children: [
                {
                  index: true,
                  lazy: async () => {
                    const { TrackingReportRoute } = await import(
                      './app/tracking/report'
                    )
                    return { Component: TrackingReportRoute }
                  },
                },
                {
                  path: 'details',
                  lazy: async () => {
                    const { OrderTrackingRoute } = await import(
                      './app/tracking/order-tracking'
                    )
                    return { Component: OrderTrackingRoute }
                  },
                },
              ],
            },
            {
              path: 'etsy-customization',
              children: [
                {
                  index: true,
                  path: 'etsy-templates',
                  lazy: async () => {
                    const { EtsyTemplatesRoute } = await import(
                      './app/etsy-customization/templates'
                    )
                    return { Component: EtsyTemplatesRoute }
                  },
                },
                {
                  path: 'bulk-fill-customization',
                  lazy: async () => {
                    const { BulkFillCustomizationRoute } = await import(
                      './app/etsy-customization/bulk-fill-customization'
                    )
                    return { Component: BulkFillCustomizationRoute }
                  },
                },
              ],
            },
            {
              path: 'sku-mapping',
              children: createSKUMappingRouter(),
            },
            {
              path: 'orders-cost',
              children: createOrdersCostRoute(),
            },

            {
              path: 'sale-report',
              children: createSaleReportRouter(),
            },
            {
              path: 'manage-stores',
              lazy: async () => {
                const { AmazonStoresRoute } = await import(
                  './app/amazon-stores'
                )
                return { Component: AmazonStoresRoute }
              },
            },
            {
              path: 'order-missing-sync',
              lazy: async () => {
                const { OrderMissingSyncRoute } = await import(
                  './app/order-missing-sync'
                )
                return { Component: OrderMissingSyncRoute }
              },
            },
            {
              path: 'confirm-ship-and-tracking',
              lazy: async () => {
                const { ConfirmShipAndTrackingRoute } = await import(
                  './app/confirm-ship-and-tracking'
                )
                return { Component: ConfirmShipAndTrackingRoute }
              },
            },
          ],
        },
        // Finance routes
        {
          path: 'finance',
          children: [
            {
              index: true,
              lazy: async () => {
                const { FinanceOverviewRoute } = await import(
                  './app/finance/overview'
                )
                return { Component: FinanceOverviewRoute }
              },
            },
            {
              path: 'transaction-classify',
              lazy: async () => {
                const { FinanceTransactionClassifyRoute } = await import(
                  './app/finance/transaction-classify'
                )
                return { Component: FinanceTransactionClassifyRoute }
              },
            },
          ],
        },
        // Design routes
        {
          path: 'design',
          children: [
            {
              index: true,
              element: <DefaultRoute redirectTo="/design/psd-tasks" />,
            },
            {
              path: 'psd-tasks',

              lazy: async () => {
                const { PSDTasksRoute } = await import('./app/design/psd-tasks')
                return { Component: PSDTasksRoute }
              },

              children: [
                {
                  path: 'team-idea',
                  lazy: async () => {
                    const { PSDTasksRoute } = await import(
                      './app/design/psd-tasks'
                    )
                    return { Component: PSDTasksRoute }
                  },
                },
                {
                  path: 'team-clone',
                  lazy: async () => {
                    const { PSDTasksRoute } = await import(
                      './app/design/psd-tasks'
                    )
                    return { Component: PSDTasksRoute }
                  },
                },
              ],
            },
            {
              path: 'png-tasks',
              lazy: async () => {
                const { PngTasksRoute } = await import('./app/design/png-tasks')
                return { Component: PngTasksRoute }
              },
            },
            {
              path: 'group-product-type',
              lazy: async () => {
                const { GroupProductTypeRoute } = await import(
                  './app/design/group-product-type'
                )
                return { Component: GroupProductTypeRoute }
              },
            },
            {
              path: 'embroider-listing',
              lazy: async () => {
                const { EmbroiderListingRoute } = await import(
                  './app/design/embroider-listing'
                )
                return { Component: EmbroiderListingRoute }
              },
            },
          ],
        },
        // Fulfillment routes
        {
          path: 'fulfillment',
          children: [
            {
              index: true,
              element: <DefaultRoute redirectTo="/fulfillment/scale" />,
            },
            {
              path: 'scale',
              lazy: async () => {
                const { ScaleRoute } = await import('./app/fulfillment/scale')
                return { Component: ScaleRoute }
              },
            },
            {
              path: 'qc',
              lazy: async () => {
                const { QCRoute } = await import('./app/fulfillment/qc')
                return { Component: QCRoute }
              },
            },
            {
              path: 'auto-fulfillment',
              children: [
                {
                  index: true,
                  element: (
                    <DefaultRoute redirectTo="/fulfillment/auto-fulfillment/amazon" />
                  ),
                },
                {
                  path: 'amazon',
                  lazy: async () => {
                    const { AutoFulfillmentRoute } = await import(
                      './app/fulfillment/auto-fulfillment'
                    )
                    return { Component: AutoFulfillmentRoute }
                  },
                },
                {
                  path: 'shopify',
                  lazy: async () => {
                    const { AutoFulfillmentRoute } = await import(
                      './app/fulfillment/auto-fulfillment'
                    )
                    return { Component: AutoFulfillmentRoute }
                  },
                },
              ],
            },
            {
              path: 'asin-tools',
              lazy: async () => {
                const { AsinToolsRoute } = await import(
                  './app/fulfillment/asin-tools'
                )
                return { Component: AsinToolsRoute }
              },
            },
            {
              path: 'listing-types',
              lazy: async () => {
                const { ListingTypesRoute } = await import(
                  './app/fulfillment/listing-types'
                )
                return { Component: ListingTypesRoute }
              },
            },
            {
              path: 'working-status',
              lazy: async () => {
                const { WorkingStatusRoute } = await import(
                  './app/fulfillment/working-status'
                )
                return { Component: WorkingStatusRoute }
              },
            },
            {
              path: 'reports',
              children: [
                {
                  path: 'vendor-fulfillment',
                  lazy: async () => {
                    const { VendorFulfillmentReportRoute } = await import(
                      './app/fulfillment/reports/vendor-fulfillment'
                    )
                    return { Component: VendorFulfillmentReportRoute }
                  },
                },
                {
                  path: 'vendor-pending',
                  lazy: async () => {
                    const { VendorPendingReportRoute } = await import(
                      './app/fulfillment/reports/vendor-pending'
                    )
                    return { Component: VendorPendingReportRoute }
                  },
                },
                {
                  path: 'god-pending',
                  lazy: async () => {
                    const { GODPendingReportRoute } = await import(
                      './app/fulfillment/reports/god-pending'
                    )
                    return { Component: GODPendingReportRoute }
                  },
                },
                {
                  path: 'working-results',
                  lazy: async () => {
                    const { WorkingResultsReportRoute } = await import(
                      './app/fulfillment/reports/working-results'
                    )
                    return { Component: WorkingResultsReportRoute }
                  },
                },
              ],
            },
          ],
        },
        // Vendor routes
        {
          path: 'vendor',
          children: [
            {
              path: 'token-vendor',
              lazy: async () => {
                const { TokenVendorRoute } = await import(
                  './app/vendor/token-vendor'
                )
                return { Component: TokenVendorRoute }
              },
            },
            {
              path: 'list-vendor',
              lazy: async () => {
                const { ListVendorRoute } = await import(
                  './app/vendor/list-vendor'
                )
                return { Component: ListVendorRoute }
              },
            },
            {
              path: 'vendor-products',
              children: [
                {
                  index: true,
                  lazy: async () => {
                    const { VendorProductsRoute } = await import(
                      './app/vendor/vendor-products'
                    )
                    return { Component: VendorProductsRoute }
                  },
                },
                {
                  path: ':id/cost-centers',
                  lazy: async () => {
                    const { CostCenterDetailRoute } = await import(
                      './app/vendor/cost-center-detail'
                    )
                    return { Component: CostCenterDetailRoute }
                  },
                },
              ],
            },
            {
              path: 'store-fulfill',
              lazy: async () => {
                const { StoreFulfillRoute } = await import(
                  './app/vendor/store-fulfill'
                )
                return { Component: StoreFulfillRoute }
              },
            },
            {
              path: 'design-product-types',
              lazy: async () => {
                const { DesignProductTypesRoute } = await import(
                  './app/vendor/ds-product-types'
                )
                return { Component: DesignProductTypesRoute }
              },
            },
          ],
        },
        {
          path: 'change-password',
          lazy: async () => {
            const { ChangePasswordRoute } = await import(
              './app/change-password'
            )
            return { Component: ChangePasswordRoute }
          },
        },
      ],
    },
    {
      path: '*',
      lazy: async () => {
        const { NotFoundRoute } = await import('./not-found')
        return { Component: NotFoundRoute }
      },
    },
  ])

function createOrdersRoute() {
  async function lazy() {
    const { OrdersRoute } = await import('./app/orders')
    return { Component: OrdersRoute }
  }

  const routes = Object.entries(PLATFORMS).map(([_, value]) => ({
    path: value,
    lazy,
  }))

  return [
    {
      index: true,
      lazy,
    },
    ...routes,
  ]
}

function createSKUMappingRouter() {
  async function lazy() {
    const { SKUMappingRoute } = await import('./app/sku-mapping')
    return { Component: SKUMappingRoute }
  }

  const routes = Object.entries(PLATFORMS).map(([_, value]) => ({
    path: value,
    lazy,
  }))

  return [
    {
      index: true,
      lazy,
    },
    ...routes,
  ]
}

function createOrdersCostRoute() {
  async function lazy() {
    const { OrdersCostRoute } = await import('./app/orders-cost')
    return { Component: OrdersCostRoute }
  }

  const routes = Object.entries(PLATFORMS).map(([_, value]) => ({
    path: value,
    lazy,
  }))

  return [
    {
      index: true,
      lazy,
    },
    ...routes,
  ]
}

function createSaleReportRouter() {
  async function lazy() {
    const { SaleReportRoute } = await import('./app/sale-report')
    return { Component: SaleReportRoute }
  }

  const routes = Object.entries(PLATFORMS).map(([_, value]) => ({
    path: value,
    lazy,
  }))

  return [
    {
      index: true,
      lazy,
    },
    ...routes,
  ]
}
