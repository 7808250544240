import { IconChevronDown } from '@tabler/icons-react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useCheckActiveNav, useNav } from '@/hooks/use-nav'
import { cn } from '@/utils/cn'

import { Button, buttonVariants } from '../ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '../ui/collapsible'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { ScrollArea, ScrollBar } from '../ui/scroll-area'
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip'

import type { NavLink } from '@/types/nav'

interface NavProps extends React.HTMLAttributes<HTMLDivElement> {
  isCollapsed: boolean
  closeNav: () => void
}

export const Nav = ({ isCollapsed, className, closeNav }: NavProps) => {
  const { modules, currentModule } = useNav()
  const items = currentModule?.items || []

  const renderLink = ({ items, ...rest }: NavLink) => {
    const key = `${rest.title}-${rest.to}`

    if (items)
      return (
        <NavLinkDropdown
          {...rest}
          items={items}
          key={key}
          closeNav={closeNav}
        />
      )

    return <NavLink {...rest} key={key} closeNav={closeNav} />
  }

  return (
    <div
      data-collapsed={isCollapsed}
      className={cn(
        'group divide-x flex border-b bg-background py-2 transition-[max-height,padding] duration-500 data-[collapsed=true]:py-2 md:border-none data-[collapsed=true]:divide-x-0',
        className,
      )}
    >
      {/* Left column */}
      <nav className="flex w-11 flex-col gap-1 group-[[data-collapsed=true]]:w-full">
        {modules.map((m) => (
          <NavLinkIconDropdown
            key={m.name}
            icon={m.icon}
            items={m.items}
            title={m.name}
          />
        ))}
      </nav>

      {/* Right column */}
      <ScrollArea className="relative flex max-h-full w-full flex-col overflow-auto">
        <nav className="flex flex-1 flex-col gap-1 group-[[data-collapsed=true]]:invisible group-[[data-collapsed=true]]:w-0">
          {items.map(renderLink)}
        </nav>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </div>
  )
}

type NavLinkProps = NavLink & {
  subLink?: boolean
  closeNav?: () => void
}

function NavLink({ title, icon, to, closeNav, subLink = false }: NavLinkProps) {
  const { checkActiveNav } = useCheckActiveNav()

  return (
    <Link
      to={to}
      onClick={closeNav}
      className={cn(
        buttonVariants({
          variant: checkActiveNav(to) ? 'secondary' : 'ghost',
          size: 'sm',
        }),
        'h-11 justify-start text-wrap rounded-none px-4 text-sm',
        subLink && 'h-10 w-full border-l border-l-slate-500 px-2',
      )}
      aria-current={checkActiveNav(to) ? 'page' : undefined}
    >
      <div className="mr-2">{icon}</div>
      {title}
    </Link>
  )
}

function NavLinkDropdown({ title, icon, items, closeNav }: NavLinkProps) {
  const isOrders = title === 'Orders'
  const isPsdTasks = title === 'PSD Tasks'
  const isSaleReport = title === 'Sale Report'

  const { checkActiveNav } = useCheckActiveNav()
  const [open, setOpen] = useState(
    !!items?.find((s) => checkActiveNav(s.to)) || isOrders || isPsdTasks,
  )

  const navigate = useNavigate()

  return (
    <Collapsible
      open={open}
      onOpenChange={(o) => {
        if (isOrders && !o) {
          return
        }
        if (isPsdTasks && !o) {
          return
        }
        if (isSaleReport && !o) {
          return
        }
        setOpen(o)
      }}
    >
      <CollapsibleTrigger
        className={cn(
          buttonVariants({
            variant: checkActiveNav(
              undefined,
              isOrders || isPsdTasks || isSaleReport,
            )
              ? 'secondary'
              : 'ghost',
            size: 'sm',
          }),
          'group h-11 w-full justify-start rounded-none px-4',
        )}
        {...(isOrders && {
          onClick: () => navigate('sales/orders'),
        })}
        {...(isPsdTasks && {
          onClick: () => navigate('design/psd-tasks/team-idea'),
        })}
        {...(isSaleReport && {
          onClick: () => navigate('sales/sale-report'),
        })}
      >
        <div className="mr-2">{icon}</div>
        {title}
        <span
          className={cn(
            'ml-auto transition-all group-data-[state="open"]:-rotate-180',
          )}
          {...(isOrders && {
            role: 'button',
            onClick: (e) => {
              e.stopPropagation()
              setOpen((o) => !o)
            },
          })}
          {...(isPsdTasks && {
            role: 'button',
            onClick: (e) => {
              e.stopPropagation()
              setOpen((o) => !o)
            },
          })}
          {...(isSaleReport && {
            role: 'button',
            onClick: (e) => {
              e.stopPropagation()
              setOpen((o) => !o)
            },
          })}
        >
          <IconChevronDown size={16} />
        </span>
      </CollapsibleTrigger>
      <CollapsibleContent className="collapsibleDropdown" asChild>
        <ul>
          {items!.map((item) => (
            <li key={item.title} className="my-1 ml-8">
              <NavLink {...item} subLink closeNav={closeNav} />
            </li>
          ))}
        </ul>
      </CollapsibleContent>
    </Collapsible>
  )
}

function NavLinkIconDropdown({ title, icon, items }: Omit<NavLinkProps, 'to'>) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { checkActiveNav } = useCheckActiveNav()

  const navigate = useNavigate()

  const isChildActive = items?.some((s) => {
    if (s.items) {
      if (s.title === 'Orders') {
        return (
          checkActiveNav(undefined, true) ||
          !!s.items.find((i) => checkActiveNav(i.to))
        )
      }
      return !!s.items.find((i) => checkActiveNav(i.to))
    }

    return checkActiveNav(s.to)
  })

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <Tooltip delayDuration={0} defaultOpen={false} open={isTooltipOpen}>
        <TooltipTrigger asChild>
          <DropdownMenuTrigger asChild>
            <Button
              variant={isChildActive ? 'secondary' : 'ghost'}
              size="icon"
              className="size-11"
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
              disabled={!items?.length}
            >
              {icon}
            </Button>
          </DropdownMenuTrigger>
        </TooltipTrigger>
        <TooltipContent
          side="right"
          className="flex items-center gap-2 capitalize"
        >
          {title}
          <IconChevronDown
            size={16}
            className="-rotate-90 text-muted-foreground"
          />
        </TooltipContent>
      </Tooltip>

      <DropdownMenuContent side="right" align="start" sideOffset={4}>
        <DropdownMenuLabel className="capitalize">{title}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {items!.map(({ title, icon, to, items }) => {
          if (!items?.length) {
            return (
              <DropdownMenuItem key={`${title}-${to}`} asChild>
                <Link
                  to={to}
                  className={cn(
                    'mb-1',
                    checkActiveNav(to) ? 'bg-secondary' : '',
                  )}
                >
                  {icon}{' '}
                  <span className="ml-2 max-w-52 text-wrap">{title}</span>
                </Link>
              </DropdownMenuItem>
            )
          }

          return (
            <DropdownMenuSub key={title + to}>
              <DropdownMenuSubTrigger
                className="mb-1"
                onClick={() => {
                  if (title === 'Orders') {
                    navigate('sales/orders')
                    setDropdownOpen(false)
                  }
                  if (title === 'PSD Tasks') {
                    navigate('design/psd-tasks/team-idea')
                    setDropdownOpen(false)
                  }
                }}
              >
                {icon} <span className="ml-2 max-w-52 text-wrap">{title}</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {items.map(({ title, icon, to }) => (
                    <DropdownMenuItem key={`${title}-${to}`} asChild>
                      <Link
                        to={to}
                        className={cn(
                          'mb-1',
                          checkActiveNav(to) ? 'bg-secondary' : '',
                        )}
                      >
                        {icon}{' '}
                        <span className="ml-2 max-w-52 text-wrap">{title}</span>
                      </Link>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
